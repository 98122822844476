import * as types from "../actions/types";
import localForage from "localforage";
import { persistReducer } from "redux-persist";

const initialState = {
  products: [],
  submitting: false,
  submitOrderError: false,
  paymentUrl: "",
  coupon: "",
  loadingCoupon: false,
  invalidCoupon: false,
  discount: 0,
  total: 0,
  subTotal: 0,
  taxes: [],
  customerName: "",
  customerEmail: "",
  customerPhone: "",
  customerCar: "",
  loadingDeliveryFees: false,
  deliveryFees: 0,
  orderType: "delivery",
  paymentType: "",
  deliveryLocation: {},
  deliveryPlace: {},
  deliveryLocationConfirmed: false,
  deliveryFormattedAddress: "",
  deliveryFlatNo: "",
  deliveryBuildingPremise: "",
  deliveryAreaStreet: "",
  deliveryLandmark: "",
  orderComments: "",
  enableWishlist: false,
  minOrderAmountError: false,
  orderLocation: "",
  orderLocationType: "",
  savedAddresses: [],
  customDomain: false,
  afActiveOrder: "",
  afOrderAccepted: false,
  employeeID: "",
  loadingEmployeeDiscount: false,
  invalidEmployeeDiscount: false,
  loadingIikoLoyaltyDiscounts: false,
  iikoLoyaltyDiscounts: [],
  addingItemToOrder: false,
  tipsAmount: "0.000",
  loadingBlueWalletData: false,
  blueWalletData: {},
};

const {
  customerName,
  customerEmail,
  customerPhone,
  customerCar,
  savedAddresses,
  orderLocation,
  orderLocationType,
  ...cleanState
} = initialState;

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_CUSTOMER_NAME:
      return {
        ...state,
        customerName: action.name,
      };
    case types.SAVE_CUSTOMER_EMAIL:
      return {
        ...state,
        customerEmail: action.email,
      };
    case types.SAVE_CUSTOMER_PHONE:
      return {
        ...state,
        customerPhone: action.phone,
      };
    case types.SAVE_CAR_NUMBER:
      return {
        ...state,
        customerCar: action.carNumber,
      };
    case types.ADD_CART:
      return {
        ...state,
        products: [
          ...state.products,
          {
            uuid: action.uuid,
            id: action.id,
            category: action.category,
            name: action.name,
            sku: action.sku,
            image: action.image,
            price: action.price,
            qty: action.qty,
            special_instructions: action.special_instructions,
            variations: action.variations,
            product: action.product,
          },
        ],
      };
    case types.REMOVE_CART:
      return {
        ...state,
        products: state.products.filter((el) => el.uuid !== action.uuid),
      };
    case types.UPDATE_CART_QTY:
      return {
        ...state,
        products: state.products.map((p) =>
          p.uuid === action.uuid ? { ...p, qty: action.qty } : p
        ),
      };
    case types.SAVE_CART_TOTALS:
      return {
        ...state,
        taxes: action.taxes,
        subTotal: action.subTotal,
        total: action.total,
      };
    case types.ORDER_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case types.ORDER_ERROR:
      return {
        ...state,
        addingItemToOrder: false,
        submitting: false,
        submitOrderError: true,
      };
    case types.LOADING_COUPON:
      return {
        ...state,
        loadingCoupon: true,
        coupon: "",
        invalidCoupon: false,
        discount: 0,
      };
    case types.SAVE_COUPON:
      return {
        ...state,
        coupon: action.coupon,
        loadingCoupon: false,
        invalidCoupon: false,
      };
    case types.DELETE_COUPON:
      return {
        ...state,
        coupon: "",
        loadingCoupon: false,
        invalidCoupon: false,
        discount: 0,
      };
    case types.INVALID_COUPON:
      return {
        ...state,
        coupon: "",
        loadingCoupon: false,
        invalidCoupon: true,
        discount: 0,
      };
    case types.LOADING_DELIVERY_FEES:
      return {
        ...state,
        loadingDeliveryFees: true,
      };
    case types.SAVE_DELIVERY_FEES:
      return {
        ...state,
        loadingDeliveryFees: false,
        deliveryFees: action.deliveryFees,
        deliveryPlace: action.deliveryPlace,
        deliveryLocation: action.deliveryLocation,
        deliveryLocationConfirmed: true,
      };
    case types.RESET_DELIVERY_LOCATION:
      return {
        ...state,
        loadingDeliveryFees: false,
        deliveryFees: 0,
        deliveryPlace: {},
        deliveryLocationConfirmed: false,
        deliveryFormattedAddress: "",
        deliveryFlatNo: "",
        deliveryBuildingPremise: "",
        deliveryAreaStreet: "",
        deliveryLandmark: "",
      };
    case types.SAVE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryFormattedAddress: action.deliveryFormattedAddress,
        deliveryFlatNo: action.deliveryFlatNo,
        deliveryBuildingPremise: action.deliveryBuildingPremise,
        deliveryAreaStreet: action.deliveryAreaStreet,
        deliveryLandmark: action.deliveryLandmark,
      };
    case types.SAVE_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.paymentType,
      };
    case types.SAVE_ORDER_COMMENTS:
      return {
        ...state,
        orderComments: action.orderComments,
      };
    case types.ENABLE_WISHLIST:
      return {
        ...state,
        enableWishlist: true,
      };
    case types.SAVE_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case types.CALCULATE_DISCOUNT:
      return {
        ...state,
        discount: action.discount,
      };
    case types.SAVE_MIN_ORDER_AMOUNT_ERROR:
      return {
        ...state,
        minOrderAmountError: action.minOrderAmountError,
      };
    case types.SAVE_ORDER_LOCATION:
      return {
        ...state,
        orderLocation: action.orderLocation,
        orderLocationType: action.orderLocationType,
      };
    case types.SAVE_ADDRESS_HISTORY:
      return {
        ...state,
        savedAddresses: [
          {
            uuid: action.uuid,
            deliveryLocation: action.deliveryLocation,
            deliveryFormattedAddress: action.deliveryFormattedAddress,
            deliveryFlatNo: action.deliveryFlatNo,
            deliveryBuildingPremise: action.deliveryBuildingPremise,
            deliveryAreaStreet: action.deliveryAreaStreet,
            deliveryLandmark: action.deliveryLandmark,
          },
          ...state.savedAddresses.slice(0, 4),
        ],
      };
    case types.REMOVE_ADDRESS:
      return {
        ...state,
        savedAddresses: state.savedAddresses.filter(
          (el) => el.uuid !== action.uuid
        ),
      };
    case types.SELECT_ADDRESS:
      return {
        ...state,
        deliveryFlatNo: action.deliveryFlatNo,
        deliveryBuildingPremise: action.deliveryBuildingPremise,
        deliveryAreaStreet: action.deliveryAreaStreet,
        deliveryLandmark: action.deliveryLandmark,
      };
    case types.ORDER_REDIRECT:
      return {
        ...state,
        ...cleanState,
        paymentUrl: action.paymentUrl,
      };
    case types.CUSTOM_DOMAIN:
      return {
        ...state,
        customDomain: true,
      };
    case types.ORDER_CLEAR:
      return { ...state, ...cleanState };
    case types.AF_ACTIVE_ORDER:
      return {
        ...state,
        afActiveOrder: action.order_id,
      };
    case types.RESET_AF_ACTIVE_ORDER:
      return {
        ...state,
        afActiveOrder: "",
      };
    case types.LOADING_EMPLOYEE_DISCOUNT:
      return {
        ...state,
        employeeID: "",
        loadingEmployeeDiscount: true,
        invalidEmployeeDiscount: false,
      };
    case types.SAVE_EMPLOYEE_DISCOUNT:
      return {
        ...state,
        employeeID: action.employeeID,
        loadingEmployeeDiscount: false,
        invalidEmployeeDiscount: false,
      };
    case types.DELETE_EMPLOYEE_DISCOUNT:
      return {
        ...state,
        employeeID: "",
        loadingEmployeeDiscount: false,
        invalidEmployeeDiscount: false,
      };
    case types.INVALID_EMPLOYEE_DISCOUNT:
      return {
        ...state,
        employeeID: "",
        loadingEmployeeDiscount: false,
        invalidEmployeeDiscount: true,
      };
    case types.LOADING_IIKO_LOYALTY_DISCOUNTS:
      return {
        ...state,
        loadingIikoLoyaltyDiscounts: true,
      };
    case types.SAVE_IIKO_LOYALTY_DISCOUNTS:
      return {
        ...state,
        loadingIikoLoyaltyDiscounts: false,
        iikoLoyaltyDiscounts: action.iikoLoyaltyDiscounts,
      };
    case types.ADDING_ITEM_TO_ORDER:
      return {
        ...state,
        addingItemToOrder: true,
        afOrderAccepted: false,
      };
    case types.ADDED_ITEM_TO_ORDER:
      return {
        ...state,
        addingItemToOrder: false,
        afOrderAccepted: false,
      };
    case types.AF_ORDER_ACCEPTED:
      return {
        ...state,
        afOrderAccepted: action.status,
      };
    case types.ADD_TIPS_AMOUNT:
      return {
        ...state,
        tipsAmount: action.tipsAmount,
      };
    case types.LOADING_BLUE_WALLET_DATA:
      return {
        ...state,
        loadingBlueWalletData: true,
      };
    case types.SAVE_BLUE_WALLET_DATA:
      return {
        ...state,
        loadingBlueWalletData: false,
        blueWalletData: action.blueWalletData,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "cartInfo",
  storage: localForage,
  whitelist: [
    "customerName",
    "customerEmail",
    "customerPhone",
    "customerCar",
    "savedAddresses",
  ],
};

export default persistReducer(persistConfig, CartReducer);
