import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ProductStyle from "./ProductStyle";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { currency_symbol, flat, fontName } from "../../../constants/utils";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import * as math from "mathjs";
import { addCart } from "../../../store/actions/cart";
import { Images } from "../../../constants";
import CustomInput from "../../CustomInput/CustomInput";
import { css } from "@emotion/css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import Badge from "../../Badge/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import chefRecommends from "../../../assets/img/emenu/chef-recommends.png";
import QuantityButton from "../Quantity/QuantityButton";
import ProductPricing from "../Pricing/ProductPricing";
import VariationPricing from "../Pricing/VariationPricing";
import axios from "axios";
import { APP_URL } from "../../../constants/Api";
import MuiAlert from "@material-ui/lab/Alert";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles(ProductStyle);
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

export default function Product(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [special_instructions, setSpecialInstructions] = useState("");
  const { currentProduct, displayVariations } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    currency,
    display_currency,
    price_decimal_places,
    product_footer_text,
    enable_adding_same_item_cart,
    enable_addtocart_cart,
    ui_strings,
    properties,
    variations,
    chef_recommends_image,
    enable_display_variation_prices,
    theme_data,
    enable_product_comments,
    taxes,
    display_prices_with_tax,
  } = menuInfo.menuData;
  const defaultProductImage = menuInfo.menuData?.default_product_image?.resized;
  const cartInfo = useSelector((state) => state.cartInfo);

  const { products, afActiveOrder } = cartInfo;

  const [addingItemToOrder, setAddingItemToOrder] = useState(false);
  const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);

  const addedToCart =
    products?.filter((e) => e.id === currentProduct.id).length > 0;

  const [qty, setQty] = useState(1);

  const { product_variations } = currentProduct;
  const [productVariations, setProductVariations] = useState(
    product_variations
      ? variations
          .filter((v) =>
            [...new Set(product_variations.map((pv) => pv.variation))].includes(
              v.id
            )
          )
          .map((v) => ({
            ...v,
            values: v.values.filter((vv) =>
              product_variations.find((pv) => pv.variation_value === vv.id)
            ),
          }))
          .map((v) => ({
            ...v,
            values: v.values.map((vv) => ({
              ...product_variations.find(
                (pv) => pv.variation_value === vv.id && pv
              ),
              name: vv.value,
              selected: false,
            })),
          }))
      : []
  );

  const [requiredVariationsSet, setRequiredVariationsSet] = useState(false);

  const [price, setPrice] = useState(currentProduct.price);
  const [discountedPrice, setDiscountedPrice] = useState(
    currentProduct.discount > 0
      ? (
          currentProduct.price -
          (currentProduct.price * currentProduct.discount) / 100
        ).toFixed(3)
      : 0
  );
  const [singlePrice, setSinglePrice] = useState(price);
  const [singleDiscountedPrice, setSingleDiscountedPrice] = useState(
    discountedPrice
  );

  const displayVariationPrice = useCallback((product) => {
    return !!product.product_variations.some(
      (variation) => variation.display_price_product === true
    );
  }, []);

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const getVariationPrice = useCallback(
    (variationPrice) => {
      let taxes;
      const price = parseFloat(variationPrice);
      const discountedPrice =
        parseFloat(variationPrice) -
        (parseFloat(variationPrice) * currentProduct.discount) / 100;
      if (currentProduct.discount > 0) {
        taxes = getTaxes(discountedPrice);
        return (parseFloat(discountedPrice) + taxes).toFixed(
          price_decimal_places
        );
      } else {
        taxes = getTaxes(price);
        return (parseFloat(price) + taxes).toFixed(price_decimal_places);
      }
    },
    [currentProduct.discount, getTaxes, price_decimal_places]
  );

  useEffect(() => {
    // Also update the product price and discounted based on variation selection
    let price = parseFloat(currentProduct.price);
    let discountedPrice =
      currentProduct.discount > 0
        ? currentProduct.price -
          (currentProduct.price * currentProduct.discount) / 100
        : 0;

    let selectedVariations = flat(
      productVariations.map((v) => v.values)
    ).filter((v) => v.selected);

    selectedVariations.forEach(function (sv) {
      price = math
        .evaluate(`${parseFloat(price)} ${sv.math} ${parseFloat(sv.price)}`)
        .toFixed(3);
      discountedPrice =
        currentProduct.discount > 0
          ? math
              .evaluate(
                `${parseFloat(discountedPrice)} ${sv.math} ${parseFloat(
                  sv.price
                )} - ${parseFloat(sv.price)} * ${currentProduct.discount}/100`
              )
              .toFixed(3)
          : 0;
    });

    setSinglePrice(parseFloat(price) + getTaxes(price));
    currentProduct.discount > 0 &&
      setSingleDiscountedPrice(
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      );

    setPrice((parseFloat(price) + getTaxes(price)) * qty);
    currentProduct.discount > 0 &&
      setDiscountedPrice(
        (parseFloat(discountedPrice) + getTaxes(discountedPrice)) * qty
      );

    // Check if all required variations are selected
    const requiredVariations = productVariations.filter(
      (v) => v.required === true
    );
    if (requiredVariations.length > 0) {
      const requiredVariationsSelected = requiredVariations.every(
        (rv) => rv.values.find((vv) => vv.selected) !== undefined
      );
      setRequiredVariationsSet(requiredVariationsSelected);
    } else {
      setRequiredVariationsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariations, qty]);

  const classes = useStyles();

  const handleVariationSelection = useCallback(
    (event) => {
      const elementsIndex = productVariations.findIndex(
        (element) => element.name === event.target.name
      );
      let newProductVariations = [...productVariations];
      newProductVariations[elementsIndex] = {
        ...newProductVariations[elementsIndex],
        values: newProductVariations[elementsIndex].values.map((vv) =>
          vv.variation_value === event.target.value
            ? { ...vv, selected: !vv.selected }
            : { ...vv, selected: false }
        ),
      };
      // Set uniqueVariation state
      setProductVariations(newProductVariations);
      // console.log(newProductVariations);
    },
    [productVariations]
  );

  const handleMultipleVariationSelection = useCallback(
    (variation_value, index) => (event) => {
      let newProductVariations = [...productVariations];
      newProductVariations[index] = {
        ...newProductVariations[index],
        values: newProductVariations[index].values.map((vv) =>
          vv.variation_value === variation_value
            ? { ...vv, selected: !vv.selected }
            : vv
        ),
      };
      // Set uniqueVariation state
      setProductVariations(newProductVariations);
      // console.log(newProductVariations);
    },
    [productVariations]
  );

  const renderVariations = useCallback(() => {
    return (
      <div>
        {productVariations.map((pv, index) => (
          <div style={{ paddingBottom: 20 }} key={index}>
            <FormControl fullWidth>
              {pv.multiple ? (
                <>
                  <FormLabel
                    component="legend"
                    style={{
                      fontSize: Number(
                        theme_data?.product_screen_variation_dropdown_title_font_size
                      ),
                      color:
                        theme_data?.product_screen_variation_dropdown_title_text_color,
                      ...fontName(
                        theme_data?.product_screen_variation_dropdown_title_font_family
                      ),
                    }}
                  >
                    <div>
                      {pv.name}
                      {pv.required ? (
                        <>
                          <span style={{ color: "red" }}> * </span>
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              lineHeight: "12px",
                              marginTop: 8,
                              marginBottom: 8,
                            }}
                          >
                            Required
                          </div>
                        </>
                      ) : null}
                      {pv.max_choices_allowed > 0 ? (
                        <div
                          style={{
                            fontSize: 12,
                            color: "grey",
                            marginTop: 8,
                            marginBottom: 8,
                          }}
                        >
                          Choose upto {pv.max_choices_allowed}
                          {pv.max_choices_allowed > 1 ? ` options` : ` option`}
                        </div>
                      ) : null}
                    </div>
                  </FormLabel>
                  <FormGroup>
                    {pv.values.map((variation, pv_index) => (
                      <FormControlLabel
                        key={pv_index}
                        control={
                          <Checkbox
                            checked={
                              pv.values.find(
                                (v) =>
                                  v.variation_value ===
                                  variation.variation_value
                              )
                                ? pv.values.find(
                                    (v) =>
                                      v.variation_value ===
                                      variation.variation_value
                                  ).selected
                                : false
                            }
                            disabled={
                              pv.max_choices_allowed > 0 &&
                              pv.values.filter((pvv) => pvv.selected).length >=
                                pv.max_choices_allowed &&
                              pv.values
                                .filter((pvv) => !pvv.selected)
                                .find(
                                  (v) =>
                                    v.variation_value ===
                                    variation.variation_value
                                ) !== undefined
                            }
                            onChange={handleMultipleVariationSelection(
                              variation.variation_value,
                              index
                            )}
                            value={variation.name}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              color:
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                              fontSize: Number(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                              ),
                              ...fontName(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                              ),
                            }}
                          >
                            {variation.name}
                            {` ${
                              variation.price > 0 &&
                              enable_display_variation_prices
                                ? `(${variation.math}
                    ${display_currency ? currency_symbol(currency) : ``}
                    ${getVariationPrice(variation.price)})`
                                : ``
                            }`}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </>
              ) : (
                <>
                  <InputLabel
                    htmlFor={`${pv.name}`}
                    className={classes.selectShrink}
                    style={{
                      fontSize: Number(
                        theme_data?.product_screen_variation_dropdown_title_font_size
                      ),
                      color:
                        theme_data?.product_screen_variation_dropdown_title_text_color,
                      ...fontName(
                        theme_data?.product_screen_variation_dropdown_title_font_family
                      ),
                      zIndex: 1,
                      padding: "0 10px",
                      pointerEvents: "none",
                    }}
                  >
                    {pv.name}
                    {pv.required ? "*" : null}
                  </InputLabel>
                  <Select
                    style={{
                      backgroundColor:
                        theme_data?.product_screen_variation_dropdown_btn_color,
                      color:
                        theme_data?.product_screen_variation_dropdown_btn_selected_text_color,
                      fontSize: Number(
                        theme_data?.product_screen_variation_dropdown_btn_selected_font_size
                      ),
                      ...fontName(
                        theme_data?.product_screen_variation_dropdown_btn_selected_font_family
                      ),
                      padding: "0 10px",
                    }}
                    className={css`
                      &::after {
                        border-bottom: 2px solid
                          ${theme_data?.product_screen_variation_dropdown_btn_selected_text_color};
                      }
                    `}
                    value={
                      pv.values.find((v) => v.selected)
                        ? pv.values.find((v) => v.selected).variation_value
                        : ""
                    }
                    onChange={handleVariationSelection}
                    inputProps={{
                      name: `${pv.name}`,
                      id: `${pv.id}`,
                    }}
                  >
                    {!pv.required && (
                      <MenuItem
                        style={{
                          color:
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                          fontSize: Number(
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                          ),
                          ...fontName(
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                          ),
                        }}
                      >
                        {"---"}
                      </MenuItem>
                    )}
                    {pv.values.map((variation) => (
                      <MenuItem
                        key={variation.variation_value}
                        style={{
                          color:
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                          fontSize: Number(
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                          ),
                          ...fontName(
                            theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                          ),
                        }}
                        value={variation.variation_value}
                      >
                        {variation.name}
                        {` ${
                          variation.price > 0 && enable_display_variation_prices
                            ? `(${variation.math}
                    ${display_currency ? currency_symbol(currency) : ``}
                    ${getVariationPrice(variation.price)})`
                            : ``
                        }`}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </div>
        ))}
      </div>
    );
  }, [
    classes,
    currency,
    display_currency,
    enable_display_variation_prices,
    getVariationPrice,
    handleMultipleVariationSelection,
    handleVariationSelection,
    productVariations,
    theme_data,
  ]);

  const closeAddToCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAddToCartSnackbar(false);
  };

  const addItemToOrder = (item) => {
    setAddingItemToOrder(true);
    let item_variations = [];
    item.variations.map((variation) =>
      variation.values
        .filter((v) => v.selected)
        .map((vv) => (vv.id ? item_variations.push(vv.id) : null))
    );

    const line_item = {
      product: item.id,
      product_sku: item.sku,
      product_name: item.name,
      product_variation: item.variations
        .map(
          (variation) =>
            `${variation.name}: ${variation.values
              .filter((v) => v.selected)
              .map((v) => v.name)
              .join(", ")}`
        )
        .join("\n    "),
      variations: item_variations,
      product_price: parseFloat(item.price).toFixed(price_decimal_places),
      product_price_currency: currency,
      quantity: item.qty,
      special_instructions: item.special_instructions,
      cart_uuid: item.uuid,
    };

    axios
      .patch(
        `${APP_URL}/en/api/order/${afActiveOrder}/`,
        JSON.stringify({
          line_items: [line_item],
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setAddingItemToOrder(false);
        history.push({
          pathname: `/order/${response.data.uuid}`,
          state: { order: response.data },
        });
      })
      .catch((error) => {
        // Error
        setAddingItemToOrder(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setShowAddToCartSnackbar(true);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        //console.log(error.config);
      });
  };

  const handleAddToCart = () => {
    if (requiredVariationsSet) {
      if (!!afActiveOrder) {
        const clean_variations = productVariations.filter(
          (v) => v.values.find((vv) => vv.selected) !== undefined
        );

        const item = {
          uuid: uuidv4(),
          id: currentProduct.id,
          category: currentProduct.category,
          name: currentProduct.name,
          sku: currentProduct.sku,
          image: currentProduct.images[0]
            ? currentProduct.images[0].image
            : null,
          price:
            currentProduct.discount > 0 ? singleDiscountedPrice : singlePrice,
          qty: qty,
          special_instructions: special_instructions,
          variations: clean_variations,
          product: currentProduct,
        };
        addItemToOrder(item);
      } else {
        dispatch(
          addCart(
            uuidv4(),
            currentProduct.id,
            currentProduct.category,
            currentProduct.name,
            currentProduct.sku,
            currentProduct.images[0] ? currentProduct.images[0].image : null,
            currentProduct.discount > 0 ? singleDiscountedPrice : singlePrice,
            qty,
            special_instructions,
            productVariations,
            currentProduct
          )
        );
        window.scroll(0, 0);
        document.body.scrollTop = 0;
        enable_addtocart_cart && history.push("/cart");
      }
    }
  };
  const productCardStyle = {
    backgroundColor: theme_data?.product_screen_description_bg_color,
  };
  const productNameStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_name_text_color,
    fontSize: Number(theme_data?.product_screen_product_name_font_size),
    ...fontName(theme_data?.product_screen_product_name_font_family),
  };
  const productPriceStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_price_text_color,
    fontSize: Number(theme_data?.product_screen_product_price_font_size),
    ...fontName(theme_data?.product_screen_product_price_font_family),
  };
  const productDescriptionStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_description_text_color,
    fontSize: Number(theme_data?.product_screen_product_description_font_size),
    ...fontName(theme_data?.product_screen_product_description_font_family),
  };
  const footerStyle = {
    color: theme_data?.product_screen_product_description_text_color,
    ...fontName(theme_data?.product_screen_product_description_font_family),
  };
  const addToCartStyle = {
    backgroundColor: theme_data?.product_screen_add_to_cart_btn_color,
    fontSize: Number(theme_data?.product_screen_add_to_cart_font_size),
    color: theme_data?.product_screen_add_to_cart_text_color,
    ...fontName(theme_data?.product_screen_add_to_cart_font_family),
  };

  const propertyBadgeStyle = {
    backgroundColor: theme_data?.product_screen_property_bg_color,
    color: theme_data?.product_screen_property_text_color,
    fontSize: Number(theme_data?.product_screen_property_font_size),
    ...fontName(theme_data?.product_screen_property_font_family),
  };

  const handleSpecialInstructionsChange = (e) =>
    setSpecialInstructions(e.target.value);

  const [tooltips, setTooltips] = useState([]);

  const handleTooltip = (value) => {
    if (value) {
      const currentIndex = tooltips.indexOf(value);
      const newTooltips = [...tooltips];

      if (currentIndex === -1) {
        newTooltips.push(value);
      } else {
        newTooltips.splice(currentIndex, 1);
      }
      setTooltips(newTooltips);
    } else {
      setTooltips([]);
    }
  };

  return (
    <div>
      <div className={classNames(classes.carousel)}>
        {currentProduct.video_file ? (
          <div className={classes.videoWrapper}>
            <ReactPlayer
              url={currentProduct.video_file}
              playing
              loop
              playsinline
              muted
              controls={false}
              width={"100%"}
              height={"100%"}
              style={{ position: "absolute", top: 0, left: 0 }}
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                    disablePictureInPicture: true,
                    disableRemotePlayback: true,
                    poster:
                      currentProduct.images.length > 0
                        ? currentProduct.images[0].image.product
                        : defaultProductImage
                        ? defaultProductImage
                        : Images.PlaceHolderProduct.image,
                  },
                },
              }}
              className={classNames("swiper-no-swiping")}
            />
          </div>
        ) : currentProduct.images?.length > 1 ? (
          <Swiper
            simulateTouch={false}
            slidesPerView={1}
            navigation={{
              nextEl: null,
              prevEl: null,
            }}
            pagination={{ clickable: true }}
          >
            {currentProduct.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image.image.product}
                  className={classNames(
                    classes.productImage,
                    currentProduct.images?.length === 1
                      ? "swiper-no-swiping"
                      : null
                  )}
                  alt={currentProduct.name}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : currentProduct.images?.length === 1 ? (
          <img
            src={currentProduct.images[0].image.product}
            className={classNames(classes.productImage)}
            alt={currentProduct.name}
          />
        ) : (
          <img
            src={
              defaultProductImage
                ? defaultProductImage
                : Images.PlaceHolderProduct.image
            }
            className={classNames(classes.productImage)}
            alt={currentProduct.name}
          />
        )}
      </div>
      <div className={classNames(classes.toolbar, classes.main)}>
        <div
          className={classNames(classes.container, classes.mainRaised)}
          style={productCardStyle}
        >
          {currentProduct.recommend ? (
            <div className={classes.chefRecommends}>
              <img
                src={
                  chef_recommends_image?.resized
                    ? chef_recommends_image.resized
                    : chefRecommends
                }
                alt={currentProduct.name}
              />
            </div>
          ) : null}

          <div className={classes.productTitle} style={productNameStyle}>
            {currentProduct.name}
          </div>

          <div className={classes.productPrice} style={productPriceStyle}>
            {price > 0 && displayVariationPrice(currentProduct) && (
              <VariationPricing
                product={currentProduct}
                price={price}
                discountedPrice={discountedPrice}
                view={"product"}
              />
            )}

            {price > 0 && !displayVariationPrice(currentProduct) && (
              <ProductPricing
                product={currentProduct}
                price={price}
                discountedPrice={discountedPrice}
              />
            )}
          </div>

          {currentProduct.properties.length > 0 ? (
            <div className={classes.propertyStyle}>
              {currentProduct.properties.map((property) => (
                <div key={property}>
                  {properties.find((x) => x.id === property).image?.icon ? (
                    <ClickAwayListener
                      mouseEvent={false}
                      onClickAway={() => handleTooltip()}
                    >
                      <div>
                        <Tooltip
                          title={properties.find((x) => x.id === property).name}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => handleTooltip(property)}
                          open={tooltips.indexOf(property) !== -1}
                          TransitionComponent={Zoom}
                        >
                          <img
                            className={classes.propertyIcon}
                            src={
                              properties.find((x) => x.id === property).image
                                .icon
                            }
                            onClick={() => handleTooltip(property)}
                            alt={properties.find((x) => x.id === property).name}
                          />
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <Badge style={propertyBadgeStyle}>
                      {properties.find((x) => x.id === property).name}
                    </Badge>
                  )}
                </div>
              ))}
            </div>
          ) : null}

          <div
            className={classes.productDescription}
            style={productDescriptionStyle}
            dangerouslySetInnerHTML={{ __html: currentProduct.description }}
          />

          <div className={classes.variations}>
            {displayVariations && renderVariations()}
          </div>

          {menuInfo.menuData.enable_order && menuInfo.isOpen && price > 0 ? (
            <div className={`add-to-cart-${currentProduct.id}`}>
              {addedToCart && !enable_adding_same_item_cart
                ? null
                : enable_product_comments && (
                    <CustomInput
                      id={"special_instructions"}
                      labelText={
                        ui_strings?.ui_str_special_instructions
                          ? ui_strings.ui_str_special_instructions
                          : "Special Instructions"
                      }
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        value: special_instructions,
                        onChange: handleSpecialInstructionsChange,
                        multiline: true,
                        rows: 2,
                        placeholder: ui_strings?.ui_str_special_instructions_placeholder
                          ? ui_strings.ui_str_special_instructions_placeholder
                          : "Example: No pepper / sugar / salt please",
                        className: css`
                          &::after {
                            border-bottom: 2px solid
                              ${theme_data?.cart_screen_form_input_border_color};
                          }
                        `,
                      }}
                    />
                  )}
              <QuantityButton
                qty={qty}
                setQty={setQty}
                maxQty={currentProduct?.pos_available_qty}
              />
              <Collapse in={showAddToCartSnackbar}>
                <MuiAlert
                  elevation={6}
                  variant={"filled"}
                  severity={"error"}
                  onClose={closeAddToCartSnackbar}
                >
                  Error adding item
                </MuiAlert>
              </Collapse>

              <Button
                className={"addToCartButton"}
                fullWidth
                disabled={
                  (addedToCart && !enable_adding_same_item_cart) ||
                  currentProduct.out_of_stock ||
                  !requiredVariationsSet ||
                  addingItemToOrder
                }
                style={addToCartStyle}
                onClick={handleAddToCart}
              >
                {addedToCart && !enable_adding_same_item_cart
                  ? ui_strings?.ui_str_already_added_to_cart
                    ? ui_strings.ui_str_already_added_to_cart
                    : "Already Added To Cart"
                  : currentProduct.out_of_stock
                  ? ui_strings?.ui_str_out_of_stock
                    ? ui_strings.ui_str_out_of_stock
                    : "Out of Stock"
                  : afActiveOrder && !addingItemToOrder
                  ? "Add to Order"
                  : afActiveOrder && addingItemToOrder
                  ? "Adding to Order"
                  : ui_strings?.ui_str_add_to_cart
                  ? ui_strings.ui_str_add_to_cart
                  : "Add To Cart"}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {product_footer_text && (
          <div
            className={classes.footer}
            style={footerStyle}
            dangerouslySetInnerHTML={{ __html: product_footer_text }}
          />
        )}
      </div>
    </div>
  );
}
