import React from "react";
import Button from "../../CustomButtons/Button";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { APP_URL } from "../../../constants/Api";

const useStyles = makeStyles(CartStyle);

export default function ReceiptButton(props) {
  const { orderData } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings, theme_data } = menuInfo.menuData;

  const buttonStyle = {
    backgroundColor: "black",
    color: "white",
    fontSize: 14,
    ...fontName(theme_data?.cart_screen_continue_shopping_btn_font_family),
  };

  return (
    <div className={classes.menuButtonContainer}>
      <EmenuNavlink
        external={`${APP_URL}/en/orders/${orderData?.uuid}/print/order.pdf`}
        target={"_blank"}
      >
        <Button fullWidth className={classes.menuButton} style={buttonStyle}>
          {ui_strings?.ui_str_download_receipt
            ? ui_strings.ui_str_download_receipt
            : "Download Receipt"}
        </Button>
      </EmenuNavlink>
    </div>
  );
}
