import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import CustomInput from "../../CustomInput/CustomInput";
import { css } from "@emotion/css";
import Button from "../../CustomButtons/Button";
import { fontName } from "../../../constants/utils";
import { addCoupon, deleteCoupon } from "../../../store/actions/cart";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(CartStyle);

export default function CartCoupon(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const {
    coupon: cCoupon,
    invalidCoupon,
    loadingCoupon,
    enableWishlist,
  } = cartInfo;

  const { ui_strings, theme_data } = menuInfo.menuData;
  const [coupon, setCoupon] = useState(cCoupon);

  const couponButtonTextStyle = {
    backgroundColor: theme_data?.cart_screen_continue_shopping_btn_color,
    fontSize: Number(theme_data?.cart_screen_cart_item_delete_btn_font_size),
    color: theme_data?.cart_screen_continue_shopping_btn_text_color,
    ...fontName(theme_data?.cart_screen_cart_item_delete_btn_font_family),
  };

  const handleCouponChange = () => {
    if (cCoupon) {
      dispatch(deleteCoupon());
    } else {
      dispatch(addCoupon(coupon));
    }
  };

  useEffect(() => {
    setCoupon(cCoupon);
  }, [cCoupon]);

  return (
    !enableWishlist && (
      <Card className={classes.couponCard}>
        <div className={classes.couponContainer}>
          <div className={classes.couponGridItem}>
            {cCoupon ? (
              <div className={classes.couponCode}>{cCoupon.code}</div>
            ) : (
              <CustomInput
                id={"couponCode"}
                labelText={
                  ui_strings?.ui_str_order_coupon
                    ? ui_strings.ui_str_order_coupon
                    : "Coupon"
                }
                error={invalidCoupon}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: coupon,
                  onChange: (e) => setCoupon(e.target.value),
                  placeholder: ui_strings?.ui_str_order_coupon_placeholder
                    ? ui_strings.ui_str_order_coupon_placeholder
                    : "Enter Coupon Code",
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            <Button
              simple
              style={couponButtonTextStyle}
              onClick={handleCouponChange}
              disabled={loadingCoupon || !coupon}
            >
              {cCoupon
                ? ui_strings?.ui_str_coupon_delete
                  ? ui_strings.ui_str_coupon_delete
                  : "Delete Coupon"
                : ui_strings?.ui_str_coupon_apply
                ? ui_strings.ui_str_coupon_apply
                : "Apply Coupon"}
            </Button>
          </div>
        </div>
      </Card>
    )
  );
}
