import React, { useCallback, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Emenu/Header/Header";
import CategoryStyle from "./CategoryStyle";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addCart } from "../../store/actions/cart";
import firebase from "../../constants/Firebase";
import { v4 as uuidv4 } from "uuid";
import VariationModal from "../../components/Emenu/Product/VariationModal";
import MenuClosed from "../../components/Emenu/MenuClosed/MenuClosed";
import { Helmet } from "react-helmet";
import RenderCategory from "../../components/Emenu/Product/RenderCategory";
import RenderPromotions from "../../components/Emenu/Product/RenderPromotions";
import RenderSubCategories from "../../components/Emenu/Product/RenderSubCategories";
import RenderSpecialCategory from "../../components/Emenu/Product/RenderSpecialCategory";

const useStyles = makeStyles(CategoryStyle);

export default function CategoryPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { match, location } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    enable_addtocart_cart,
    ui_strings,
    categories,
    theme_data,
    license,
    taxes,
    display_prices_with_tax,
    price_decimal_places,
  } = menuInfo.menuData;
  const currentCategory = location.categoryData
    ? location.categoryData
    : categories.filter(
        (category) => category.id === Number(match.params.id)
      )[0];

  const [subcatagories, setSubCategories] = useState(
    categories.filter((category) => category.parent === currentCategory.id)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.category_screen_bg_color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubCategories(
      categories.filter((category) => category.parent === currentCategory.id)
    );

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // Analytics
    if (license) {
      firebase.analytics().logEvent("category_page_view", {
        license: license,
        category_name: currentCategory.name,
        category_id: currentCategory.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const classes = useStyles();

  const categoryBgStyle = {
    backgroundImage: `url(${menuInfo?.menuData?.category_background?.resized})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme_data?.category_screen_bg_color,
    height: "100vh",
    width: "inherit",
    maxWidth: "inherit",
    position: "fixed",
    zIndex: "-1000",
  };

  const [showVariationModel, setShowVariationModal] = useState(false);

  const [modalProduct, setModalProduct] = useState({});

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const handleCartButton = (product) => {
    let price = (parseFloat(product.price) + getTaxes(product.price)).toFixed(
      price_decimal_places
    );
    let discountedPrice =
      product.discount > 0
        ? parseFloat(product.price) -
          (parseFloat(product.price) * product.discount) / 100
        : 0;
    if (product.discount > 0) {
      discountedPrice = (
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      ).toFixed(price_decimal_places);
    }

    if (product.product_variations.length > 0) {
      setModalProduct(product);
      setShowVariationModal(true);
    } else {
      setModalProduct({});

      dispatch(
        addCart(
          uuidv4(),
          product.id,
          product.category,
          product.name,
          product.sku,
          product.images[0] ? product.images[0].image : null,
          discountedPrice > 0 ? discountedPrice : price,
          1,
          "",
          [],
          product
        )
      );
      enable_addtocart_cart && history.push("/cart");
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Header goback={true} brand={currentCategory.name} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${currentCategory.name} | ${menuInfo.menuData?.name} | eMenu.ae`
            : "eMenu.ae"}
        </title>
      </Helmet>
      <div
        style={categoryBgStyle}
        className={classNames(classes.toolbar, classes.main)}
      />
      <div>
        <div className={classes.container}>
          {!menuInfo.isOpen && (
            <MenuClosed
              text={
                ui_strings?.ui_str_menu_closed
                  ? ui_strings?.ui_str_menu_closed
                  : "We are currently closed"
              }
            />
          )}
          {currentCategory.enable_special_category ? (
            <RenderSpecialCategory
              currentCategory={currentCategory}
              handleCartButton={handleCartButton}
            />
          ) : subcatagories.length > 0 ? (
            <RenderSubCategories
              currentCategory={currentCategory}
              subcatagories={subcatagories}
            />
          ) : currentCategory.promotions.length > 0 &&
            currentCategory.products.length === 0 ? (
            <RenderPromotions currentCategory={currentCategory} />
          ) : (
            <RenderCategory
              currentCategory={currentCategory}
              handleCartButton={handleCartButton}
            />
          )}
        </div>
      </div>
      <VariationModal
        product={modalProduct}
        handleOpen={showVariationModel}
        handleClose={() => setShowVariationModal(false)}
      />
    </div>
  );
}
