import React, { useCallback, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Emenu/Header/Header.js";
import HomeStyle from "./HomeStyle";
import { useDispatch, useSelector } from "react-redux";
import { is_open } from "../../constants/utils";
import firebase from "../../constants/Firebase";
import MenuClosed from "../../components/Emenu/MenuClosed/MenuClosed";
import { Helmet } from "react-helmet";
import { addCart } from "../../store/actions/cart";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import VariationModal from "../../components/Emenu/Product/VariationModal";
import RenderSpecialCategory from "../../components/Emenu/Product/RenderSpecialCategory";
import RenderSubCategories from "../../components/Emenu/Product/RenderSubCategories";
import CartOrderType from "../../components/Emenu/Cart/CartOrderType";

const useStyles = makeStyles(HomeStyle);

export default function HomePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    ui_strings,
    timezone,
    theme_data,
    home_header_text,
    home_footer_text,
    license,
    home_display_all_categories,
    home_view_format,
    enable_addtocart_cart,
    taxes,
    display_prices_with_tax,
    price_decimal_places,
    enable_display_order_type_home,
    categories: allCategories,
    al_futtaim_enabled,
  } = menuInfo.menuData;

  const { orderLocation, orderLocationType, afActiveOrder } = cartInfo;

  const [categories] = useState(
    allCategories.filter(
      (category) =>
        !category.parent && is_open(category.category_hours, [], timezone)
    )
  );

  const [currentCategory] = useState({
    description: home_header_text,
    view_format: home_view_format,
    special_category_type: "all",
    special_category_display_categories: true,
  });

  const [showVariationModel, setShowVariationModal] = useState(false);

  const [modalProduct, setModalProduct] = useState({});

  const classes = useStyles();

  const categoryBgStyle = {
    backgroundImage: `url(${menuInfo?.menuData?.category_background?.resized})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme_data?.home_screen_bg_color,
    height: "100vh",
    width: "inherit",
    maxWidth: "inherit",
    position: "fixed",
    zIndex: "-1000",
  };

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const handleCartButton = (product) => {
    let price = (parseFloat(product.price) + getTaxes(product.price)).toFixed(
      price_decimal_places
    );
    let discountedPrice =
      product.discount > 0
        ? parseFloat(product.price) -
          (parseFloat(product.price) * product.discount) / 100
        : 0;
    if (product.discount > 0) {
      discountedPrice = (
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      ).toFixed(price_decimal_places);
    }

    if (product.product_variations.length > 0) {
      setModalProduct(product);
      setShowVariationModal(true);
    } else {
      setModalProduct({});
      dispatch(
        addCart(
          uuidv4(),
          product.id,
          product.category,
          product.name,
          product.sku,
          product.images[0] ? product.images[0].image : null,
          discountedPrice > 0 ? discountedPrice : price,
          1,
          "",
          [],
          product
        )
      );
      enable_addtocart_cart && history.push("/cart");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.home_screen_bg_color;
    if (license) {
      firebase.analytics().logEvent("home_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [displayCartOrderType, setDisplayCartOrderType] = useState(false);

  useEffect(() => {
    if (afActiveOrder) {
      setDisplayCartOrderType(false);
    } else {
      if (al_futtaim_enabled) {
        if (orderLocation && orderLocationType === "Table") {
          setDisplayCartOrderType(true);
        } else {
          setDisplayCartOrderType(false);
        }
      } else {
        setDisplayCartOrderType(enable_display_order_type_home);
      }
    }
  }, [
    afActiveOrder,
    al_futtaim_enabled,
    enable_display_order_type_home,
    orderLocation,
    orderLocationType,
  ]);

  return (
    <div className={classes.mainContainer}>
      <Header
        brand={ui_strings?.ui_str_home ? ui_strings?.ui_str_home : "Home"}
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${
                ui_strings?.ui_str_home ? ui_strings?.ui_str_home : "Home"
              } | ${menuInfo.menuData?.name} | eMenu.ae`
            : "eMenu.ae"}
        </title>
      </Helmet>
      <div
        style={categoryBgStyle}
        className={classNames(classes.toolbar, classes.main)}
      />
      <div>
        <div className={classes.container}>
          {!menuInfo.isOpen && (
            <MenuClosed
              text={
                ui_strings?.ui_str_menu_closed
                  ? ui_strings?.ui_str_menu_closed
                  : "We are currently closed"
              }
            />
          )}
          {displayCartOrderType && <CartOrderType displayHome={true} />}

          {home_display_all_categories ? (
            <RenderSpecialCategory
              currentCategory={currentCategory}
              handleCartButton={handleCartButton}
            />
          ) : (
            <RenderSubCategories
              currentCategory={currentCategory}
              subcatagories={categories}
            />
          )}
          <VariationModal
            product={modalProduct}
            handleOpen={showVariationModel}
            handleClose={() => setShowVariationModal(false)}
          />
          <div
            className={classes.homeFooterText}
            dangerouslySetInnerHTML={{ __html: home_footer_text }}
          />
        </div>
      </div>
    </div>
  );
}
