export const LOADING = "LOADING";
export const REFRESHING_MENU = "REFRESHING_MENU";
export const SAVE_LANGUAGES = "SAVE_LANGUAGES";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SAVE_MENU_INFO = "SAVE_MENU_INFO";
export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const UPDATE_CART_QTY = "UPDATE_CART_QTY";
export const SAVE_CART_TOTALS = "SAVE_CART_TOTALS";
export const ORDER_SUBMITTING = "ORDER_SUBMITTING";
export const ORDER_REDIRECT = "ORDER_REDIRECT";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_CLEAR = "ORDER_CLEAR";
export const GET_ERRORS = "GET_ERRORS";
export const SEARCH_TEXT = "SEARCH_TEXT";
export const MENU_FORM_SUBMITTED = "MENU_FORM_SUBMITTED";
export const MENU_IS_OPEN = "MENU_IS_OPEN";
export const MENU_IS_CLOSED = "MENU_IS_CLOSED";
export const SAVE_LICENSE = "SAVE_LICENSE";
export const CUSTOM_DOMAIN = "CUSTOM_DOMAIN";
export const LOADING_COUPON = "LOADING_COUPON";
export const SAVE_COUPON = "SAVE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const INVALID_COUPON = "INVALID_COUPON";
export const CALCULATE_DISCOUNT = "CALCULATE_DISCOUNT";
export const LOADING_DELIVERY_FEES = "LOADING_DELIVERY_FEES";
export const SAVE_DELIVERY_FEES = "SAVE_DELIVERY_FEES";
export const RESET_DELIVERY_LOCATION = "RESET_DELIVERY_LOCATION";
export const SAVE_DELIVERY_ADDRESS = "SAVE_DELIVERY_ADDRESS";
export const SAVE_PAYMENT_TYPE = "SAVE_PAYMENT_TYPE";
export const SAVE_ORDER_COMMENTS = "SAVE_ORDER_COMMENTS";
export const ENABLE_WISHLIST = "ENABLE_WISHLIST";
export const SAVE_ORDER_TYPE = "SAVE_ORDER_TYPE";
export const SAVE_CUSTOMER_NAME = "SAVE_CUSTOMER_NAME";
export const SAVE_CUSTOMER_EMAIL = "SAVE_CUSTOMER_EMAIL";
export const SAVE_CUSTOMER_PHONE = "SAVE_CUSTOMER_PHONE";
export const SAVE_CAR_NUMBER = "SAVE_CAR_NUMBER";
export const SAVE_MIN_ORDER_AMOUNT_ERROR = "SAVE_MIN_ORDER_AMOUNT_ERROR";
export const SAVE_ORDER_LOCATION = "SAVE_ORDER_LOCATION";
export const SAVE_ADDRESS_HISTORY = "SAVE_ADDRESS_HISTORY";
export const REMOVE_ADDRESS = "REMOVE_ADDRESS";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const LOADING_EMPLOYEE_DISCOUNT = "LOADING_EMPLOYEE_DISCOUNT";
export const SAVE_EMPLOYEE_DISCOUNT = "SAVE_EMPLOYEE_DISCOUNT";
export const DELETE_EMPLOYEE_DISCOUNT = "DELETE_EMPLOYEE_DISCOUNT";
export const INVALID_EMPLOYEE_DISCOUNT = "INVALID_EMPLOYEE_DISCOUNT";
export const AF_ACTIVE_ORDER = "AF_ACTIVE_ORDER";
export const RESET_AF_ACTIVE_ORDER = "RESET_AF_ACTIVE_ORDER";
export const LOADING_IIKO_LOYALTY_DISCOUNTS = "LOADING_IIKO_LOYALTY_DISCOUNTS";
export const SAVE_IIKO_LOYALTY_DISCOUNTS = "SAVE_IIKO_LOYALTY_DISCOUNTS";
export const ADDING_ITEM_TO_ORDER = "ADDING_ITEM_TO_ORDER";
export const ADDED_ITEM_TO_ORDER = "ADDED_ITEM_TO_ORDER";
export const AF_ORDER_ACCEPTED = "AF_ORDER_ACCEPTED";
export const ADD_TIPS_AMOUNT = "ADD_TIPS_AMOUNT";
export const LOADING_BLUE_WALLET_DATA = "LOADING_BLUE_WALLET_DATA";
export const SAVE_BLUE_WALLET_DATA = "SAVE_BLUE_WALLET_DATA";
