import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import OrderStyle from "../../../views/OrderPage/OrderStyle";

const useStyles = makeStyles(OrderStyle);
const OrderTotals = ({ orderData, balance_due, setBalanceDue }) => {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { ui_strings, price_decimal_places, currency } = menuInfo.menuData;
  const { tipsAmount: cTipsAmount } = cartInfo;

  const {
    created,
    discounts,
    padded_order_id,
    delivery_fees,
    total,
    line_items,
    sub_total,
    taxes,
    order_comments,
    additional_data,
    status,
    payments,
    balance_due: oBalanceDue,
  } = orderData;

  const [freeDelivery, setFreeDelivery] = useState(false);
  const [orderTotal, setOrderTotal] = useState(total);
  const [tipsAmount, setTipsAmount] = useState(cTipsAmount);

  const cartSubTotalStr = ui_strings?.ui_str_cart_subtotal
    ? ui_strings.ui_str_cart_subtotal.replace(/%s/g, line_items?.length)
    : `Subtotal (${line_items?.length} items)`;

  const payment_types = {
    cash: "Cash",
    machine: "Card Machine",
    card: "Online Payments",
    loyalty: "Loyalty",
    blue_wallet: "Blue Wallet",
  };

  useEffect(() => {
    if (discounts) {
      const freeDeliveryDiscount = discounts.find(
        (discount) => discount.free_delivery
      );
      setFreeDelivery(!!freeDeliveryDiscount);
    }
  }, [discounts]);

  useEffect(() => {
    if (cTipsAmount === "0.000" && additional_data?.tips_amount) {
      setOrderTotal(Number(total));
      setTipsAmount(additional_data?.tips_amount);
      setBalanceDue(oBalanceDue);
    } else if (cTipsAmount !== "0.000" && additional_data?.tips_amount) {
      setOrderTotal(
        Number(total) +
          Number(cTipsAmount) -
          Number(additional_data?.tips_amount)
      );
      setTipsAmount(cTipsAmount);
      setBalanceDue(oBalanceDue);
    } else {
      setOrderTotal(Number(total) + Number(cTipsAmount));
      setTipsAmount(cTipsAmount);
      setBalanceDue(oBalanceDue + Number(cTipsAmount));
    }
  }, [additional_data, cTipsAmount, oBalanceDue, setBalanceDue, total]);

  return (
    <>
      <div style={{ fontSize: 12 }}>
        {format(new Date(created), "MMMM do, yyyy H:mma")}
      </div>
      <div style={{ fontWeight: "bold" }}>Order # {padded_order_id}</div>
      {["rejected", "canceled"].includes(status) && (
        <div style={{ fontWeight: "bold", color: "red" }}>
          {`Order was ${status}, please contact your server`}
        </div>
      )}
      <div style={{ marginTop: 20, width: "100%" }}>
        <table className={classes.lineItemTable}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Qty</th>
              <th>Product</th>
              <th style={{ textAlign: "right" }}>
                Price
                <br />({currency})
              </th>
            </tr>
          </thead>
          <tbody>
            {line_items?.map((item, index) => (
              <tr
                key={index}
                style={{
                  verticalAlign: "top",
                  textDecoration:
                    item.status === "Deleted" ? "line-through" : "none",
                }}
              >
                <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                <td>
                  <div>{item?.product_name}</div>
                  <div style={{ fontSize: 12, whiteSpace: "pre-line" }}>
                    {item?.product_variation}
                  </div>
                  <div style={{ fontSize: 12, fontStyle: "italic" }}>
                    {item?.special_instructions}
                  </div>
                </td>
                <td style={{ textAlign: "right" }}>
                  {parseFloat(item?.product_price * item?.quantity).toFixed(
                    price_decimal_places
                  )}
                </td>
              </tr>
            ))}
            {sub_total && sub_total > 0 && (
              <tr style={{ fontSize: 14 }}>
                <td colSpan={2} style={{ textAlign: "right" }}>
                  {cartSubTotalStr}
                </td>
                <td style={{ textAlign: "right" }}>
                  {parseFloat(sub_total).toFixed(price_decimal_places)}
                </td>
              </tr>
            )}
            {additional_data?.iiko_loyalty_discounts?.length > 0 &&
              additional_data?.iiko_loyalty_discounts?.map(
                (discount, index) => (
                  <tr style={{ fontSize: 14 }} key={index}>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      {discount?.name}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      -{" "}
                      {discount?.discounts
                        .reduce((prev, d) => prev + d.discountSum, 0)
                        .toFixed(price_decimal_places)}
                    </td>
                  </tr>
                )
              )}
            {taxes &&
              taxes.length > 0 &&
              taxes.map((tax, index) => (
                <tr key={index} style={{ fontSize: 14 }}>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {tax.name}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(tax.amount).toFixed(price_decimal_places)}
                  </td>
                </tr>
              ))}
            {delivery_fees && delivery_fees > 0 && (
              <tr style={{ fontSize: 14 }}>
                <td colSpan={2} style={{ textAlign: "right" }}>
                  Delivery Fees
                </td>
                <td
                  style={{
                    textAlign: "right",
                    textDecoration: freeDelivery ? "line-through" : "none",
                  }}
                >
                  {parseFloat(delivery_fees).toFixed(price_decimal_places)}
                </td>
              </tr>
            )}
            {discounts.length > 0 &&
              discounts.map((discount, index) => (
                <tr style={{ fontSize: 14 }} key={index}>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    Discount ({discount?.name})
                  </td>
                  <td style={{ textAlign: "right" }}>
                    -{" "}
                    {parseFloat(discount?.amount_value).toFixed(
                      price_decimal_places
                    )}
                  </td>
                </tr>
              ))}
            <tr style={{ fontSize: 14 }}>
              <td colSpan={2} style={{ textAlign: "right" }}>
                Tips
              </td>
              <td style={{ textAlign: "right" }}>
                {parseFloat(tipsAmount).toFixed(price_decimal_places)}
              </td>
            </tr>
            <tr style={{ fontSize: 14, fontWeight: "bold" }}>
              <td colSpan={2} style={{ textAlign: "right" }}>
                Total
              </td>
              <td style={{ textAlign: "right" }}>
                {parseFloat(String(Number(orderTotal))).toFixed(
                  price_decimal_places
                )}
              </td>
            </tr>

            {payments?.length > 0 && (
              <>
                {payments.map((payment, index) => (
                  <tr key={index} style={{ fontSize: 14 }}>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      {payment_types[payment.payment_type]}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      -{" "}
                      {parseFloat(String(Number(payment.amount))).toFixed(
                        price_decimal_places
                      )}
                    </td>
                  </tr>
                ))}
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    Balance Due
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(String(Number(balance_due))).toFixed(
                      price_decimal_places
                    )}
                  </td>
                </tr>
              </>
            )}

            {order_comments ? (
              <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                <td colSpan={3}>
                  Order Comments:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontStyle: "italic",
                    }}
                  >
                    {order_comments}
                  </span>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrderTotals;
