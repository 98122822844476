import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Emenu/Header/Header.js";
import { useSelector } from "react-redux";
import InformationStyle from "./InformationStyle";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(InformationStyle);

export default function InformationPage(props) {
  const { match, location } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { pages, theme_data, license } = menuInfo.menuData;
  const currentPage = location.pageData
    ? location.pageData
    : pages.filter((page) => page.id === Number(match.params.id))[0];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.category_screen_bg_color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Analytics
    if (license) {
      firebase.analytics().logEvent("info_page_view", {
        license: license,
        page_name: currentPage.title,
        page_id: currentPage.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <Header brand={currentPage.title} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${currentPage.title} | ${menuInfo.menuData?.name} | eMenu.ae`
            : "eMenu.ae"}
        </title>
      </Helmet>
      <div className={classNames(classes.toolbar, classes.main)}>
        <div className={classes.container}>
          <div
            className={classes.page}
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
          />
        </div>
      </div>
    </div>
  );
}
