import React from "react";
import { NavLink } from "react-router-dom";

export default function EmenuNavlink(props) {
  const {
    to,
    disabled,
    external,
    children,
    target = "_self",
    className,
    ...rest
  } = props;

  return disabled ? (
    <div className={className}>{children}</div>
  ) : external ? (
    <a rel="noreferrer" href={external} className={className} target={target}>
      {children}
    </a>
  ) : (
    <NavLink className={className} to={to} {...rest}>
      {children}
    </NavLink>
  );
}
