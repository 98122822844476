import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Emenu/Header/Header.js";
import AlFuttaimCheckoutStyle from "./AlFuttaimCheckoutStyle";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import CartButton from "../../components/Emenu/Cart/CartButton";
import { fontName, isEmpty } from "../../constants/utils";
import axios from "axios";
import { APP_URL } from "../../constants/Api";
import AlFuttaimTips from "../../components/Emenu/Cart/AlFuttaimTips";
import AlFuttaimBlueRewards from "../../components/Emenu/Cart/AlFuttaimBlueRewards";
import AlFuttaimPaymentOptions from "../../components/Emenu/Cart/AlFuttaimPaymentOptions";
import OrderTotals from "../../components/Emenu/Checkout/OrderTotals";
import { useHistory } from "react-router-dom";
import { fetchBlueWalletData } from "../../store/actions/cart";

const useStyles = makeStyles(AlFuttaimCheckoutStyle);

export default function AlFuttaimCheckoutPage(props) {
  const { match, location } = props;
  const orderUUId = match.params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    ui_strings,
    theme_data,
    license,
    al_futtaim_enabled,
  } = menuInfo.menuData;

  const { afActiveOrder } = cartInfo;

  const [orderData, setOrderData] = useState(location?.state?.order || {});

  const [balance_due, setBalanceDue] = useState(orderData?.balance_due || 0);

  const [showThanks, setShowThanks] = useState(!!location?.state?.order);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;

    if (license) {
      firebase.analytics().logEvent("order_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrderData = () => {
    axios
      .get(`${APP_URL}/en/api/order/${orderUUId}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOrderData(response.data);
          setShowThanks(false);
          // if not accepted or preparing, then redirect to order page
          if (!["accepted", "preparing"].includes(response.data.status)) {
            history.push(`/order/${orderUUId}`);
          } else {
            dispatch(fetchBlueWalletData(orderUUId));
          }
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        //console.log(error.config);
      });
  };

  useEffect(() => {
    if (orderUUId && isEmpty(location?.state?.order)) {
      fetchOrderData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUUId, location]);

  const successTextStyle = {
    fontSize: Number(theme_data?.cart_screen_cart_success_font_size),
    color: theme_data?.cart_screen_cart_success_text_color,
    ...fontName(theme_data?.cart_screen_cart_success_font_family),
    textAlign: "center",
    marginBottom: 30,
  };

  return (
    <div className={classes.mainContainer}>
      <Header brand={"Checkout"} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `Checkout | ${menuInfo.menuData?.name} | eMenu.ae`
            : "eMenu.ae"}
        </title>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          {!isEmpty(orderData) && (
            <div className={classes.orderReceipt}>
              {showThanks && (
                <div style={successTextStyle}>
                  {ui_strings?.ui_str_order_thanks
                    ? ui_strings.ui_str_order_thanks
                    : "Thank you for placing the order. Our team will get back to you shortly!"}
                </div>
              )}
              <OrderTotals
                orderData={orderData}
                balance_due={balance_due}
                setBalanceDue={setBalanceDue}
              />
            </div>
          )}
          <div className={classes.menuTotalsWave}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343 13.98">
              <path
                fill="#fff"
                d="M0 0v8.114a4 4 0 0 0 5.691 3.625l11.674-5.445a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l15.673 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.381 0l15.675 7.311a4 4 0 0 0 3.381 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.673-7.311a4 4 0 0 1 3.382 0l15.674 7.311a4 4 0 0 0 3.382 0l15.674-7.311a4 4 0 0 1 3.382 0l11.673 5.445A4 4 0 0 0 343 8.114V0Z"
                data-name="Path 54"
              />
            </svg>
          </div>

          {al_futtaim_enabled &&
          !!afActiveOrder &&
          orderUUId === afActiveOrder ? (
            <>
              <AlFuttaimTips orderData={orderData} />
              <AlFuttaimBlueRewards
                orderData={orderData}
                fetchOrderData={fetchOrderData}
              />
              <AlFuttaimPaymentOptions
                orderData={orderData}
                fetchOrderData={fetchOrderData}
                balanceDue={balance_due}
              />
            </>
          ) : (
            <CartButton noText />
          )}
        </div>
      </div>
    </div>
  );
}
