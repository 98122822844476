import React from "react";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import { fontName, is_open } from "../../../constants/utils";
import { useSelector } from "react-redux";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import { Images } from "../../../constants";
import CardBody from "../../Card/CardBody";
import chefRecommendsIcon from "../../../assets/img/emenu/chef-recommends-icon.png";
import VariationPricing from "../Pricing/VariationPricing";
import ProductPricing from "../Pricing/ProductPricing";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import Badge from "../../Badge/Badge";

const useStyles = makeStyles(CategoryStyle);

export default function RenderProducts(props) {
  const { products: categoryProducts, handleCartButton, view_format } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    theme_data,
    taxes,
    display_prices_with_tax,
    timezone,
    default_product_image,
    default_product_image_width,
    default_product_image_height,
    enable_adding_same_item_cart,
    properties,
    ui_strings,
  } = menuInfo.menuData;
  const { products: cartProducts } = cartInfo;

  const defaultProductImage = {
    image: default_product_image?.resized,
    width: default_product_image_width,
    height: default_product_image_height,
  };

  const displayVariationPrice = (product) => {
    return !!product.product_variations.some(
      (variation) => variation.display_price === true
    );
  };

  const getTaxes = (price) => {
    let taxAmount = 0;
    if (taxes.length > 0 && display_prices_with_tax) {
      for (let tax of taxes) {
        if (tax.type === "percentage") {
          taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
        }
      }
    }
    return taxAmount;
  };

  const getPrice = (product) => {
    return (
      parseFloat(product.price) + getTaxes(parseFloat(product.price))
    ).toFixed(3);
  };

  const getDiscountedPrice = (product) => {
    if (product.discount > 0) {
      const discountedPrice =
        parseFloat(product.price) -
        (parseFloat(product.price) * product.discount) / 100;
      return (
        parseFloat(discountedPrice) + getTaxes(parseFloat(discountedPrice))
      );
    } else {
      return 0;
    }
  };

  const productPriceStyleZeroCondition =
    Number(theme_data?.category_screen_product_price_font_size) === 0
      ? { lineHeight: 0 }
      : null;

  const productPriceStyle = {
    fontSize: Number(theme_data?.category_screen_product_price_font_size),
    color: theme_data?.category_screen_product_price_text_color,
    ...fontName(theme_data?.category_screen_product_price_font_family),
    ...productPriceStyleZeroCondition,
  };

  const productNameStyle = {
    fontSize: Number(theme_data?.category_screen_product_title_font_size),
    color: theme_data?.category_screen_product_title_text_color,
    ...fontName(theme_data?.category_screen_product_title_font_family),
  };

  const productSmallDescriptionStyle = {
    fontSize: Number(
      theme_data?.category_screen_product_small_description_font_size
    ),
    color: theme_data?.category_screen_product_small_description_text_color,
    ...fontName(
      theme_data?.category_screen_product_small_description_font_family
    ),
    whiteSpace: "pre-line",
    padding: "8px 0",
  };

  const propertyBadgeStyle = {
    backgroundColor: theme_data?.product_screen_property_bg_color,
    color: theme_data?.product_screen_property_text_color,
    fontSize: Number(theme_data?.product_screen_property_font_size),
    ...fontName(theme_data?.product_screen_property_font_family),
  };

  return (
    <>
      {view_format === 1 || view_format === 2 ? (
        // GRID FORMAT
        <div className={classes.products}>
          {categoryProducts
            .filter((product) => is_open(product.product_hours, [], timezone))
            .map((product) => (
              <Card
                key={product.id}
                className={classes.card}
                style={{
                  backgroundColor: !product.hidden_title
                    ? theme_data?.category_screen_product_bg_color
                    : "transparent",
                  width: product.fullwidth ? "95%" : "45%",
                }}
                plain={!product.card_shadow}
              >
                <EmenuNavlink
                  disabled={product.disable_link || product.out_of_stock}
                  external={product.external_link}
                  to={{
                    pathname: `/product/${product.id}`,
                    categoryData: product,
                  }}
                >
                  {view_format === 1 && (
                    <CardHeader
                      image
                      plain
                      noShadow={!product.card_shadow}
                      children={
                        !product.hidden_image ? (
                          <img
                            src={
                              product.images[0]
                                ? product.images[0].image.product
                                : defaultProductImage.image
                                ? defaultProductImage.image
                                : Images.PlaceHolderProduct.image
                            }
                            className={classes.categoryImage}
                            width={
                              product.images[0]
                                ? product.images[0].image_width
                                : defaultProductImage.width
                                ? defaultProductImage.width
                                : Images.PlaceHolderProduct.width
                            }
                            height={
                              product.images[0]
                                ? product.images[0].image_height
                                : defaultProductImage.height
                                ? defaultProductImage.height
                                : Images.PlaceHolderProduct.height
                            }
                            alt={product.name}
                          />
                        ) : null
                      }
                    />
                  )}
                  {!product.hidden_title && (
                    <CardBody
                      className={classes.productTitle}
                      children={
                        <div
                          style={{
                            padding: product.hidden_image ? "14px 0" : 0,
                          }}
                        >
                          {product.recommend && (
                            <div className={classes.chefRecommends}>
                              <img
                                src={chefRecommendsIcon}
                                alt={"Chef Recommends"}
                              />
                            </div>
                          )}

                          {product.out_of_stock && (
                            <div className={classes.outOfStock}>
                              {ui_strings?.ui_str_out_of_stock
                                ? ui_strings.ui_str_out_of_stock
                                : "Out of Stock"}
                            </div>
                          )}

                          <div style={productNameStyle}>{product.name}</div>

                          {product.properties.length > 0 && (
                            <div className={classes.propertyStyle}>
                              {product.properties.map((property) => {
                                const p = properties.find(
                                  (x) => x.id === property
                                );
                                return (
                                  <div key={property}>
                                    {p.image?.icon ? (
                                      <img
                                        className={classes.propertyIcon}
                                        src={p.image.icon}
                                        alt={p.name}
                                      />
                                    ) : (
                                      <Badge style={propertyBadgeStyle}>
                                        {p.name}
                                      </Badge>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div style={productPriceStyle}>
                            {product.price > 0 &&
                              displayVariationPrice(product) && (
                                <VariationPricing
                                  product={product}
                                  price={getPrice(product)}
                                  discountedPrice={getDiscountedPrice(product)}
                                  view={view_format}
                                />
                              )}
                            {product.price > 0 &&
                              !displayVariationPrice(product) && (
                                <ProductPricing
                                  product={product}
                                  price={getPrice(product)}
                                  discountedPrice={getDiscountedPrice(product)}
                                />
                              )}
                          </div>
                        </div>
                      }
                    />
                  )}
                </EmenuNavlink>
              </Card>
            ))}
        </div>
      ) : view_format === 3 || view_format === 4 ? (
        // LIST FORMAT
        <div className={classes.productsList}>
          {categoryProducts
            .filter((product) => is_open(product.category_hours, [], timezone))
            .map((product) => (
              <Card
                key={product.id}
                className={classes.card}
                style={{
                  backgroundColor: !product.hidden_title
                    ? theme_data?.category_screen_product_bg_color
                    : "transparent",
                }}
                plain={!product.card_shadow}
              >
                <EmenuNavlink
                  disabled={
                    product.disable_link ||
                    product.out_of_stock ||
                    view_format === 4
                  }
                  external={product.external_link}
                  to={{
                    pathname: `/product/${product.id}`,
                    categoryData: product,
                  }}
                  className={`product-${product.id}`}
                >
                  {!product.hidden_title && (
                    <CardBody
                      className={classes.productTitleList}
                      children={
                        <div className={classes.productListItem}>
                          {view_format !== 4 && !product.hidden_image && (
                            <img
                              src={
                                product.images[0]
                                  ? product.images[0].image.product
                                  : defaultProductImage.image
                                  ? defaultProductImage.image
                                  : Images.PlaceHolderProduct.image
                              }
                              className={classes.productImageList}
                              width={
                                product.images[0]
                                  ? product.images[0].image_width
                                  : defaultProductImage.width
                                  ? defaultProductImage.width
                                  : Images.PlaceHolderProduct.width
                              }
                              height={
                                product.images[0]
                                  ? product.images[0].image_height
                                  : defaultProductImage.height
                                  ? defaultProductImage.height
                                  : Images.PlaceHolderProduct.height
                              }
                              alt={product.name}
                            />
                          )}
                          <div
                            className={classes.productDetailsList}
                            style={{
                              padding: product.hidden_image
                                ? "14px 0"
                                : "0 10px",
                            }}
                          >
                            {product.recommend && (
                              <div className={classes.chefRecommends}>
                                <img
                                  src={chefRecommendsIcon}
                                  alt={"Chef Recommends"}
                                />
                              </div>
                            )}

                            <div
                              style={productNameStyle}
                              className={classes.ProductTitleWithCart}
                            >
                              <div
                                style={{
                                  margin: product.hidden_image ? "auto" : 0,
                                }}
                              >
                                {product.name}
                              </div>

                              {view_format === 4 &&
                                menuInfo.menuData.enable_order &&
                                menuInfo.isOpen &&
                                product.price > 0 && (
                                  <IconButton
                                    disabled={
                                      (cartProducts?.filter(
                                        (e) => e.id === product.id
                                      ).length > 0 &&
                                        !enable_adding_same_item_cart) ||
                                      product.out_of_stock
                                    }
                                    style={
                                      cartProducts?.filter(
                                        (e) => e.id === product.id
                                      ).length > 0 &&
                                      !enable_adding_same_item_cart
                                        ? { padding: 5 }
                                        : {
                                            color:
                                              theme_data?.category_screen_product_add_to_card_icon_color,
                                            padding: 5,
                                          }
                                    }
                                    color="inherit"
                                    aria-label="add-to-cart"
                                    onClick={() => handleCartButton(product)}
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                )}
                            </div>

                            {product.out_of_stock && (
                              <div
                                className={classes.outOfStock}
                                style={{
                                  width: "fit-content",
                                  padding: "2px 6px",
                                }}
                              >
                                {ui_strings?.ui_str_out_of_stock
                                  ? ui_strings.ui_str_out_of_stock
                                  : "Out of Stock"}
                              </div>
                            )}

                            {product.properties.length > 0 && (
                              <div
                                className={classes.propertyStyle}
                                style={{ justifyContent: "start" }}
                              >
                                {product.properties.map((property) => (
                                  <div key={property}>
                                    {properties.find((x) => x.id === property)
                                      .image?.icon ? (
                                      <img
                                        className={classes.propertyIcon}
                                        src={
                                          properties.find(
                                            (x) => x.id === property
                                          ).image.icon
                                        }
                                        alt={
                                          properties.find(
                                            (x) => x.id === property
                                          ).name
                                        }
                                      />
                                    ) : (
                                      <Badge style={propertyBadgeStyle}>
                                        {
                                          properties.find(
                                            (x) => x.id === property
                                          ).name
                                        }
                                      </Badge>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                            <div style={productSmallDescriptionStyle}>
                              {product.small_description}
                            </div>
                            <div style={productPriceStyle}>
                              {product.price > 0 &&
                                displayVariationPrice(product) && (
                                  <VariationPricing
                                    product={product}
                                    price={getPrice(product)}
                                    discountedPrice={getDiscountedPrice(
                                      product
                                    )}
                                    view={view_format}
                                  />
                                )}
                              {product.price > 0 &&
                                !displayVariationPrice(product) && (
                                  <ProductPricing
                                    product={product}
                                    price={getPrice(product)}
                                    discountedPrice={getDiscountedPrice(
                                      product
                                    )}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  )}
                </EmenuNavlink>
              </Card>
            ))}
        </div>
      ) : null}
    </>
  );
}
