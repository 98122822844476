const ENV = {
  dev: {
    ENVIRONMENT: "DEV",
    APP_URL: "http://localhost:8000",
    FIREBASE_API_KEY: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
    FIREBASE_PROJECT_ID: "emenu-543ab",
    FIREBASE_APP_ID: "1:540059913797:web:2dbce0456e876f14a29d2c",
    FIREBASE_MEASUREMENT_ID: "G-Z3ZCBGP2VC",
  },
  staging: {
    ENVIRONMENT: "STAGING",
    APP_URL: "https://staging.app.emenu.ae",
    FIREBASE_API_KEY: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
    FIREBASE_PROJECT_ID: "emenu-543ab",
    FIREBASE_APP_ID: "1:540059913797:web:2dbce0456e876f14a29d2c",
    FIREBASE_MEASUREMENT_ID: "G-Z3ZCBGP2VC",
  },
  prod: {
    ENVIRONMENT: "PRODUCTION",
    APP_URL: "https://app.emenu.ae",
    FIREBASE_API_KEY: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
    FIREBASE_PROJECT_ID: "emenu-543ab",
    FIREBASE_APP_ID: "1:540059913797:web:b45414831389a4b4a29d2c",
    FIREBASE_MEASUREMENT_ID: "G-4ZVRC0HEZX",
  },
  test: {
    ENVIRONMENT: "TEST",
    APP_URL: "https://test-app.emenu.ae",
    FIREBASE_API_KEY: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
    FIREBASE_PROJECT_ID: "emenu-543ab",
    FIREBASE_APP_ID: "1:540059913797:web:2dbce0456e876f14a29d2c",
    FIREBASE_MEASUREMENT_ID: "G-Z3ZCBGP2VC",
  },
};

function getEnvVars() {
  switch (process.env.REACT_APP_TARGET_ENV) {
    case "development":
      return ENV.dev;
    case "staging":
      return ENV.staging;
    case "test":
      return ENV.test;
    default:
      return ENV.prod;
  }
}

export const APP_URL = getEnvVars().APP_URL;

export const FIREBASE_CONFIG = {
  apiKey: getEnvVars().FIREBASE_API_KEY,
  projectId: getEnvVars().FIREBASE_PROJECT_ID,
  appId: getEnvVars().FIREBASE_APP_ID,
  measurementId: getEnvVars().FIREBASE_MEASUREMENT_ID,
};
