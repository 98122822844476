import React, { useEffect, useRef, useState } from "react";
import RenderProducts from "./RenderProducts";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import { flat, fontName, is_open } from "../../../constants/utils";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { NavHashLink } from "react-router-hash-link";
import useMeasure from "react-use/lib/useMeasure";
import { useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { css } from "@emotion/css";

const useStyles = makeStyles(CategoryStyle);

export default function RenderSpecialCategory(props) {
  const history = useHistory();
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const [ref, bounds] = useMeasure();
  const { timezone, categories, theme_data } = menuInfo.menuData;
  const { currentCategory, handleCartButton } = props;
  const {
    description,
    view_format,
    special_category_type,
    special_category_display_categories,
  } = currentCategory;

  const [all_products] = useState(
    flat(
      categories
        .filter(
          (category) =>
            !category.disable_link &&
            !category.external_link &&
            !category.enable_special_category &&
            is_open(category.category_hours, [], timezone)
        )
        .map((category) => category.products)
    )
      .filter((product) =>
        special_category_type === "discounted" ? product.discount > 0 : product
      )
      .filter((product) => is_open(product.product_hours, [], timezone))
  );

  const [all_categories] = useState(
    categories
      .filter((category) => is_open(category.category_hours, [], timezone))
      .filter((category) =>
        special_category_type === "all"
          ? category.products.length > 0
          : special_category_type === "all_in_category"
          ? category.parent === currentCategory.id &&
            category.products.length > 0
          : category.products.filter((product) => product.discount > 0).length >
            0
      )
  );

  const navClasses = classNames({
    [classes.scrollCategoryNav]: true,
    [classes.stickyNav]: true,
    "sticky-nav": true,
  });

  const navItemClasses = classNames({
    [classes.navItem]: true,
    [css`
      background-color: ${theme_data.category_screen_nav_button_color};
      &.is-active {
        background-color: ${theme_data?.category_screen_nav_button_selected_color};
      }
    `]: true,
  });

  const navItemStyle = {
    fontSize: Number(theme_data?.category_screen_nav_button_font_size),
    color: theme_data?.category_screen_nav_button_text_color,
    ...fontName(theme_data?.category_screen_nav_button_font_family),
  };

  const catTitleStyle = {
    fontSize: Number(
      theme_data?.category_screen_special_category_title_font_size
    ),
    color: theme_data?.category_screen_special_category_title_text_color,
    textTransform:
      theme_data?.category_screen_special_category_title_text_transform,
    ...fontName(theme_data?.category_screen_special_category_title_font_family),
  };

  const yOffset = -150;
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const catInView = (category, inView, entry) => {
    if (inView) {
      history.replace(`#cat-${category.id}`);
    }
  };

  const navRef = useRef();

  useEffect(() => {
    return history.listen((location) => {
      navRef?.current.querySelector(".is-active")?.scrollIntoView({
        inline: "center",
      });
    });
  }, [history]);

  return (
    <div
      style={{
        marginTop: description ? 0 : 90,
      }}
      ref={ref}
    >
      {description && (
        <div
          className={classes.categoryDescription}
          style={{
            marginTop: special_category_display_categories ? 70 : 20,
            marginBottom: 20,
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {!special_category_display_categories && (
        <RenderProducts
          products={all_products}
          handleCartButton={handleCartButton}
          view_format={view_format}
        />
      )}
      {special_category_display_categories && (
        <>
          <div
            className={navClasses}
            style={{ maxWidth: bounds.width + 30 }}
            ref={navRef}
          >
            {all_categories.map((category) => (
              <NavHashLink
                key={category.id}
                scroll={(el) => scrollWithOffset(el)}
                to={`#cat-${category.id}`}
                activeClassName="is-active"
                className={navItemClasses}
                style={navItemStyle}
                replace
              >
                {category.name}
              </NavHashLink>
            ))}
          </div>

          {all_categories.map((category) => (
            <InView
              key={category.id}
              id={`cat-${category.id}`}
              threshold={0.5}
              onChange={(inView, entry) => catInView(category, inView, entry)}
            >
              {category.image?.product ? (
                <img
                  src={category.image?.product}
                  className={classes.categoryImage}
                  width={category?.image_width}
                  height={category?.image_height}
                  alt={category.name}
                  style={{ maxWidth: "100%", width: "100%" }}
                />
              ) : (
                <div
                  className={classes.specialCategoryName}
                  style={catTitleStyle}
                >
                  {category.name}
                </div>
              )}

              <RenderProducts
                products={
                  special_category_type === "discounted"
                    ? category.products.filter(
                        (product) => product.discount > 0
                      )
                    : category.products
                }
                handleCartButton={handleCartButton}
                view_format={view_format}
              />
            </InView>
          ))}
        </>
      )}
    </div>
  );
}
