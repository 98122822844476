import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Emenu/Header/Header.js";
import CartStyle from "./CartStyle";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import CartButton from "../../components/Emenu/Cart/CartButton";
import CartProducts from "../../components/Emenu/Cart/CartProducts";
import CartTotals from "../../components/Emenu/Cart/CartTotals";
import CartCoupon from "../../components/Emenu/Cart/CartCoupon";
import CartCheckoutButton from "../../components/Emenu/Cart/CartCheckoutButton";
import CartDeliveryButton from "../../components/Emenu/Cart/CartDeliveryButton";
import CartPaymentType from "../../components/Emenu/Cart/CartPaymentType";
import CartOrderComments from "../../components/Emenu/Cart/CartOrderComments";
import CartOrderType from "../../components/Emenu/Cart/CartOrderType";
import { enableWishlist, saveOrderType } from "../../store/actions/cart";
import Button from "../../components/CustomButtons/Button";
import AlFuttaimEmployeeDiscount from "../../components/Emenu/Cart/AlFuttaimEmployeeDiscount";
import { useHistory } from "react-router-dom";
import { flat, is_open } from "../../constants/utils";
import UpsellProducts from "../../components/Emenu/Cart/UpsellProducts";

const useStyles = makeStyles(CartStyle);

export default function CartPage(props) {
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    cart_footer_text,
    ui_strings,
    theme_data,
    license,
    enable_wishlist,
    enable_address_google_maps,
    enable_coupons,
    al_futtaim_enabled,
    enable_display_order_type_home,
    categories,
    timezone,
  } = menuInfo.menuData;
  const {
    products,
    orderType,
    paymentUrl,
    orderLocation,
    orderLocationType,
    loadingCoupon,
    loadingEmployeeDiscount,
    loadingIikoLoyaltyDiscounts,
    afActiveOrder,
  } = cartInfo;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;

    if (license) {
      firebase.analytics().logEvent("cart_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (afActiveOrder) {
      history.push(`/order/${afActiveOrder}`);
    }
  }, [afActiveOrder, history]);

  useEffect(() => {
    if (
      al_futtaim_enabled &&
      orderLocation &&
      orderLocationType &&
      orderType === "delivery"
    ) {
      dispatch(saveOrderType("dine_in"));
    } else if (
      al_futtaim_enabled &&
      !orderLocation &&
      !orderLocationType &&
      orderType === "delivery"
    ) {
      dispatch(saveOrderType("takeaway"));
    } else if (
      !al_futtaim_enabled &&
      orderLocation &&
      orderLocationType &&
      orderType === "delivery"
    ) {
      dispatch(saveOrderType("dine_in"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [al_futtaim_enabled, orderType, orderLocation, orderLocationType]);

  useEffect(() => {
    if (enable_wishlist) {
      dispatch(enableWishlist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable_wishlist]);

  const classes = useStyles();

  const redirectHandler = (paymentUrl) => {
    window.open(paymentUrl, "_self");
  };

  useEffect(() => {
    if (paymentUrl) {
      redirectHandler(paymentUrl);
    }
  }, [paymentUrl]);

  const [upsell_products] = useState(
    flat(
      categories
        .filter(
          (category) =>
            !category.external_link &&
            is_open(category.category_hours, [], timezone)
        )
        .map((category) => category.products)
    )
      .filter((product) => is_open(product.product_hours, [], timezone))
      .filter(
        (product) =>
          product.upsell_cart && !product.out_of_stock && !product.disable_link
      )
  );

  return (
    <div className={classes.mainContainer}>
      <Header
        goback={true}
        brand={ui_strings?.ui_str_cart ? ui_strings.ui_str_cart : "Cart"}
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${ui_strings?.ui_str_cart ? ui_strings.ui_str_cart : "Cart"} | ${
                menuInfo.menuData?.name
              } | eMenu.ae`
            : "eMenu.ae"}
        </title>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          {menuInfo.isOpen && !paymentUrl && (
            <>
              <CartButton />
              {!enable_display_order_type_home && <CartOrderType />}
              <CartProducts />
              {products.length > 0 && (
                <>
                  <CartOrderComments />
                  {al_futtaim_enabled && <AlFuttaimEmployeeDiscount />}
                  {enable_coupons && <CartCoupon />}
                  <CartTotals />
                  {upsell_products.length > 0 && (
                    <UpsellProducts products={upsell_products} />
                  )}
                  {orderType === "delivery" && enable_address_google_maps && (
                    <CartDeliveryButton />
                  )}
                  <CartPaymentType />
                  <CartCheckoutButton />
                </>
              )}
            </>
          )}
          {paymentUrl && (
            <div>
              <Button
                fullWidth
                onClick={() => redirectHandler(paymentUrl)}
                disabled={
                  loadingCoupon ||
                  loadingEmployeeDiscount ||
                  loadingIikoLoyaltyDiscounts
                }
              >
                {ui_strings?.ui_str_order_redirect_paymenturl
                  ? ui_strings.ui_str_order_redirect_paymenturl
                  : "You're being redirected to payment page ... "}
              </Button>
            </div>
          )}
          <div
            style={{ marginTop: 20 }}
            dangerouslySetInnerHTML={{ __html: cart_footer_text }}
          />
          <div className={classes.emptySpace} />
        </div>
      </div>
    </div>
  );
}
