import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import WelcomeStyle from "./WelcomeStyle.js";
import firebase from "../../constants/Firebase";
import backgroundImage from "../../assets/img/emenu/bg.jpg";

const useStyles = makeStyles(WelcomeStyle);

export default function WelcomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    firebase.analytics().logEvent("welcome_page_view");
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>eMenu</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left} />
            <div className={classes.pullCenter}>
              <a
                href="https://www.kptac.com"
                target="_blank"
                className={classes.footerBrand}
                rel="noreferrer"
              >
                KPTAC Technologies
              </a>
              <br />
              <a
                href={"tel:97143793455"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                +971 4 3793455
              </a>
              <br />
              <a
                href={"mailto:info@emenu.ae"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                info@emenu.ae
              </a>
              <br />
              <a
                href={"https://www.emenu.ae"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                www.emenu.ae
              </a>
              <ul className={classes.socialLinks}>
                <li>
                  <Button
                    href="https://facebook.com/digital.emenu"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://instagram.com/digital.emenu"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
            <div className={classes.rightLinks} />
          </div>
        }
      />
    </div>
  );
}
